import { isEmpty } from 'lodash';
import {
  addDoc,
  collection,
  deleteField,
  doc,
  FieldValue,
  updateDoc,
} from 'firebase/firestore';
import {
  CreatedByRef,
  CreateOutletData,
  Location,
  OutletChanges,
  OutletStatus,
  Role,
  User,
} from '../../../../global';
import { firestore } from '../utils/firebase';

type UpdateOutletData = {
  active?: boolean;
  status?: OutletStatus;
  actionRequired?: boolean;
  changes?: OutletChanges | FieldValue;
  name?: string;
  primaryDistributor?: {
    id: string;
    name: string;
    phone: string;
  };
  primaryFSA?: {
    id: string;
    name: string;
    phone: string;
  };
  rejectionComment?: string;
  location?: Location;
  address?: Address;
  deletionRequested?: boolean | FieldValue;
  deletionRequestedBy?: {
    id: string;
    name: string;
    phone: string;
  };
  confirmedBy?: CreatedByRef;
  rejectOutletChanges?: CreatedByRef;
  rejectedBy?: CreatedByRef;
  updatedBy?: {
    email?: string;
    id: string;
    name: string;
    phone: string;
    roles: Role[];
    changes: Object
  };
  confirmedOutletChanges?: CreatedByRef;
  rejectedOutletChanges?: CreatedByRef;
};

type Address = {
  street: string;
  nr: string;
  landmark: string;
  city: string;
  area: string;
};

export const updateOutlet = async (
  outletId: string,
  data: UpdateOutletData
) => {
  const ref = doc(firestore, 'outlets', outletId);
  await updateDoc(ref, data);
};

export const createOutlet = async (data: CreateOutletData, user: User) => {
  await addDoc(collection(firestore, 'outlets'), {...data, createdBy: {
    id: user.id,
    name: user.name,
    phone: user.phone,
    roles: user.roles,
  }});
};

export const confirmOutletRegistration = async (id: string, user: User) => {
  await updateOutlet(id, { status: 'confirmed', active: true, confirmedBy: {
    id: user.id,
    name: user.name,
    phone: user.phone,
    roles: user.roles,
  }});
};

export const rejectOutletRegistration = async (id: string, comment: string, user: User) => {
  await updateOutlet(id, { status: 'rejected', rejectionComment: comment, rejectedBy: {
    id: user.id,
    name: user.name,
    phone: user.phone,
    roles: user.roles,
  } });
};

export const confirmOutletRejection = async (id: string) => {
  await updateOutlet(id, { status: 'rejectionConfirmed' });
};

export const registerOutletChanges = async (
  id: string,
  force: boolean,
  changes: OutletChanges,
  user: User
) => {
  if (force) {
    await updateOutlet(id, {
      status: 'confirmed',
      ...changes,
      changes: deleteField(),
      updatedBy: {
        id: user.id,
        name: user.name,
        phone: user.phone,
        roles: user.roles,
        changes: changes,
      }
    });
  } else {
    const updateObj = {};
    if ('location' in changes && changes.firstCapture) {
      updateObj['location'] = changes.location;
      delete changes.location;
      delete changes.firstCapture;
    }
    if (isEmpty(changes)) {
      await updateOutlet(id, {
        ...updateObj,
      });
    } else {
      Object.keys(changes).forEach((key) => {
        if (key === 'firstCapture') return;
        updateObj[`changes.${key}`] = changes[key];
      });
      await updateOutlet(id, {
        actionRequired: true,
        status: 'changes',
        ...updateObj,
        updatedBy: {
          id: user.id,
          name: user.name,
          phone: user.phone,
          roles: user.roles,
          changes: updateObj,
        }
      });
    }
  }
};

export const confirmOutletChanges = async (
  id: string,
  changes: OutletChanges,
  user: User,
) => {
  await updateOutlet(id, {
    status: 'confirmed',
    ...changes,
    changes: deleteField(),
    confirmedOutletChanges: {
      id: user.id,
      name: user.name,
      phone: user.phone,
      roles: user.roles,
    }
  });
};

export const rejectOutletChanges = async (
  id: string,
  changes: OutletChanges,
  comment: string,
  user: User,
) => {
  await updateOutlet(id, {
    rejectionComment: comment,
    status: 'changesRejected',
    changes: deleteField(),
    rejectedOutletChanges: {
      id: user.id,
      name: user.name,
      phone: user.phone,
      roles: user.roles,
    }
  });
};

export const confirmOutletChangesRejection = async (id: string) => {
  await updateOutlet(id, {
    status: 'confirmed',
  });
};

export const requestOutletDeletion = async (
  id: string,
  requestedBy: { id: string; name: string, phone: string }
) => {
  await updateOutlet(id, {
    deletionRequested: true,
    deletionRequestedBy: requestedBy,
  });
};

export const cancelOutletDeletion = async (id: string) => {
  await updateOutlet(id, {
    deletionRequested: deleteField(),
  });
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { Complaint as ComplaintType } from '../../../../global';

type ComplaintProps = {
  complaint: ComplaintType;
};

const Complaint = ({ complaint }: ComplaintProps) => {
  const navigate = useNavigate();
  return (
    <li
      className={
        'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer  bg-white '
      }
      onClick={() => {
        navigate(`/complaints/${complaint.id}`);
      }}
    >
      <div className="grid grid-cols-10 w-full">
        <div className="col-span-9">
          <p className="text-lh-head-black align-middle font-bold col-span-1">
            {complaint.complaintTitle}
          </p>
          <p className="col-span-3 text-lefft self-center mt-1 text-lh-head-black">
          {complaint.complaint}
        </p>
        </div>
        <p className="col-span-1 text-right self-center mt-1 text-lh-head-black font-bold mr-4">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Complaint;

import { forwardRef, useEffect, useImperativeHandle } from 'react';
import Input from '../../../components/forms/Input';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Select from '../../../components/forms/Select';

type POSM = {
  chair: boolean | null;
  table: boolean | null;
  parasol: boolean | null;
  bunting: boolean | null;
  tarpauline: boolean | null;
  container: boolean | null;
  abs: boolean | null;
  aframe: boolean | null;
}

type Props = {
  editable: boolean;
  setRequestPOSM: any;
  setRequestPOSMIsValid: any;
  requestedPOSM: {
    posmRequest: string;
    noRequest: boolean;
    chair: boolean;
    table: boolean;
    parasol: boolean;
    bunting: boolean;
    tarpauline: boolean;
    container: boolean;
    abs: boolean;
    aframe: boolean;
  };
  posm: POSM;
  posmRequestLoading: boolean;
  posmRequest: object;
};

const VisitStepRequestPOSM = forwardRef((props: Props, ref) => {
  const { register, handleSubmit, control, formState: { errors }, getValues } = useForm({
    defaultValues: { ...props.requestedPOSM },
  });

  const posmRequest = useWatch({
    control,
    name: 'posmRequest',
  })

  const hasPOSMRequested = useWatch({
    control,
    name: ['chair', 'table', 'parasol', 'bunting', 'tarpauline', 'container', 'abs', 'aframe']
  });

  const posmRequestOptions: RegisterOptions = {
      required: 'Please select if you want to capture POSM or not!',
    };

  useEffect(() => {
    if((hasPOSMRequested.indexOf(true) !== -1 || posmRequest === 'no')) {
      props.setRequestPOSMIsValid(true);
    } else {
      props.setRequestPOSMIsValid(false);
    }
  }, [props, hasPOSMRequested, posmRequest]);

  useEffect(()=> {
    if(!props.posmRequestLoading && props.posmRequest) {
      props.setRequestPOSMIsValid(true);
    }

  }, [props.posmRequestLoading, props.posmRequest, props])

  useImperativeHandle(ref, () => ({
    saveRequestPOSM() {
      doSaveRequestPOSM();
    },
  }));

  const doSaveRequestPOSM = () => {
    const values = getValues();
    const posmObj = {
      chair: values.chair ?? false,
      table: values.table ?? false,
      parasol: values.parasol ?? false,
      bunting: values.bunting ?? false,
      tarpauline: values.tarpauline ?? false, 
      container:values.container ?? false,
      abs: values.abs ?? false,
      aframe: values.aframe ?? false,
    }

    props.setRequestPOSM(posmObj);
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          {!props.posmRequestLoading && !props.posmRequest && (
            <>
              <label
                htmlFor="cementTypeSandcrete"
                className="font-bold col-span-12 mb-2 text-lh-head-black"
              >
                Do you want to request POSM?
              </label>
              <Select
                register={register}
                name="posmRequest"
                items={[
                  {
                    key: 'yes',
                    label: 'Yes',
                    value: 'yes',
                  },
                  {
                    key: 'no',
                    label: 'No',
                    value: 'no',
                  },
                ]}
                placeholder="Do you want to request POSM?"
                error={errors.posmRequest}
                options={posmRequestOptions}
                className="col-span-12"
              />
            </>
          )}
          {!props.posmRequestLoading && props.posmRequest && (
            <label
              htmlFor="cementTypeSandcrete"
              className="font-bold col-span-12 mb-2 text-lh-head-black"
            >
              You already requested POSM for this outlet.
            </label>
          )}
          {posmRequest === 'yes' && (
          <>
            <p className={'col-span-12 text-left mt-4 font-semibold pl-6'}>
              Request POSM
            </p>
            <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
              <p className="text-center">CHAIR</p>
              <Input
                register={register}
                type="checkbox"
                name="chair"
                placeholder="0"
                disabled={props.editable || props.posm.chair}
                className={'col-span-3 text-right mb-2 font-normal '}
              />
            </p>
            <p className={'col-span-6 text-left mt-4 mb-2 font-normal'}>
              <p className="text-center">TABLE</p>
              <Input
                register={register}
                type="checkbox"
                name="table"
                placeholder="0"
                disabled={props.editable || props.posm.table}
                className={'col-span-3 text-right mb-2 font-normal '}
              />
            </p>
            <p className={'col-span-6 text-left mb-2 font-normal'}>
              <p className="text-center">PARASOL</p>
              <Input
                register={register}
                type="checkbox"
                name="parasol"
                placeholder="0"
                disabled={props.editable || props.posm.parasol}
                className={'col-span-3 text-right mb-2 font-normal '}
              />
            </p>
            <p className={'col-span-6 text-left mb-2 font-normal'}>
              <p className="text-center">BUNTING</p>
              <Input
                register={register}
                type="checkbox"
                name="bunting"
                placeholder="0"
                disabled={props.editable || props.posm.bunting}
                className={'col-span-3 text-right mb-2 font-normal '}
              />
            </p>
            <p className={'col-span-6 text-left mb-2 font-normal'}>
              <p className="text-center">TARPAULINE</p>
              <Input
                register={register}
                type="checkbox"
                name="tarpauline"
                placeholder="0"
                disabled={props.editable || props.posm.tarpauline}
                className={'col-span-3 text-right mb-2 font-normal '}
              />
            </p>
            <p className={'col-span-6 text-left mb-2 font-normal'}>
              <p className="text-center">CONTAINER</p>
              <Input
                register={register}
                type="checkbox"
                name="container"
                placeholder="0"
                disabled={props.editable || props.posm.container}
                className={'col-span-3 text-right mb-2 font-normal '}
              />
            </p>
            <p className={'col-span-6 text-left mb-2 font-normal'}>
              <p className="text-center">ABS</p>
              <Input
                register={register}
                type="checkbox"
                name="abs"
                placeholder="0"
                disabled={props.editable || props.posm.abs}
                className={'col-span-3 text-right mb-2 font-normal '}
              />
            </p>
            <p className={'col-span-6 text-left mb-2 font-normal'}>
              <p className="text-center">A-FRAME</p>
              <Input
                register={register}
                type="checkbox"
                name="aframe"
                placeholder="0"
                disabled={props.editable || props.posm.aframe}
                className={'col-span-3 text-right mb-2 font-normal '}
              />
            </p>
          </>
          )}
        </div>
        <p></p>
      </div>
    </>
  );
});
export default VisitStepRequestPOSM;

import { FirebaseError } from 'firebase/app';
import {
  Query,
  query,
  where,
  orderBy,
  FirestoreError,
  Unsubscribe,
  collection,
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { useState, useEffect, useMemo } from 'react';
import {
  PageDirection,
  MissingDeliveryType,
  MissingDeliveryStatus,
  CreateMissingDeliveryData,
} from '../../../../global';
import { useCheckAccess } from '../auth/use-checkAccess';
import { useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { unflatten } from '../utils/helpers';
import { usePagination } from '../utils/usePagination';
import {
  SearchQuery,
  usePaginationTypesense,
} from '../utils/usePaginationTypesense';
import { useAuth } from './use-auth';
import { createMissingDelivery } from '../db/missingDeliveries';

type MissingDeliveriesHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  missingDeliveries: MissingDeliveryType[];
};

type MissingDeliveriesHookProps = {
  status?: MissingDeliveryStatus;
  userId?: string;
  pageSize?: number;
  direction: PageDirection;
};

type MissingDeliveryHook = {
  delivery: MissingDeliveryType;
  error: FirebaseError;
  loading: boolean;
  create: (data: CreateMissingDeliveryData) => void;
};

export const useMissingDeliveries = ({
  status = 'pending',
  pageSize = 10,
  direction = 'asc',
}: MissingDeliveriesHookProps): MissingDeliveriesHook => {
  const { id } = useAuth();
  const [missingDeliveriesQuery, setMissingDeliveriesQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    missingDeliveriesQuery,
    pageSize
  );

  useEffect(() => {
    if (id) {
      let q = query(
        collection(firestore, 'missingDeliveries'),
        where('userIds', 'array-contains', id),
        orderBy('deliveryDate', direction)
      );

      if (status) {
        q = query(q, where('status', 'in', status));
      }
      setMissingDeliveriesQuery(q);
    }
  }, [id, status, direction]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      missingDeliveries: values
        ? values.map((v) => docToJSON(v) as MissingDeliveryType)
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useMissingDeliveriesTypesense = ({
  status = 'pending',
  searchText,
  pageSize = 10,
  direction = 'asc',
}) => {
  const { id, typesenseKeyUserIds } = useAuth();
  const [missingDeliveriesQuery, setMissingDeliveriesQuery] =
    useState<SearchQuery>();
  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense(
      missingDeliveriesQuery,
      pageSize,
      'missingDeliveries',
      typesenseKeyUserIds
    );

  useEffect(() => {
    let categoryFilter = '';
    if (status) {
      categoryFilter += `status:=['${status}]`;
    }

    if (typesenseKeyUserIds && id && searchText) {
      let search = {
        q: searchText,
        query_by: 'distributor.name,productNameInternal,productName',
        filter_by: `${categoryFilter}`,
        sort_by: `sort_by:created_at${direction}`,
      };

      setMissingDeliveriesQuery(search);
    } else if (missingDeliveriesQuery) {
      setMissingDeliveriesQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status, pageSize, direction, searchText, typesenseKeyUserIds]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      missingDeliveries: values
        ? values.map((v) => {
            return unflatten({
              ...v.document,
              id: v.document.id,
              createdAt: v.document.createdAt * 1000,
            }) as MissingDeliveryType;
          })
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useMissingDelivery = (id?: string): MissingDeliveryHook => {
  const checkAccess = useCheckAccess();
  const { error, loading, setLoading, setError, setValue, value } =
    useLoadingValue<MissingDeliveryType | null, FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      const ref = doc(firestore, `missingDeliveries/${id}`);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists) {
            setValue(docToJSON(doc) as MissingDeliveryType);
          } else {
            setValue(null);
          }
          setLoading(false);
        },
        setError
      );
    } else {
      setValue(null);
    }
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const create = async (data: CreateMissingDeliveryData) => {
    if (!checkAccess(['distributor', 'sop', 'fsm'])) return;
    try {
      setLoading(true);
      await createMissingDelivery(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  return useMemo(
    () => ({
      delivery: value,
      error,
      loading,
      create,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, value]
  );
};

import { Outlet, Product } from '../../../../global';

export const getTotalVolume = (products: Product): number => {
  let totalVolume = 0;
  if (!products) return 0;
  Object.keys(products).forEach((key) => {
    let conversionRate: number;
    if (key === 'classic' || key === 'supaset' || key === 'watershield'
      || key === 'pop'
      //tempProduct2 || key === 'tempProduct2'
      //tempProduct3 || key === 'tempProduct3'
      //tempProduct4 || key === 'tempProduct4'
    ) {
      conversionRate = 0.05;
    } else {
      conversionRate = 0.02;
    }
    totalVolume += +products[key] * conversionRate;
  });
  return Math.round((totalVolume + Number.EPSILON) * 100) / 100;
};

export const sortByStatus = (a: Outlet, b: Outlet) => {
  const sa = a?.status;
  const sb = b?.status;
  if (sa === 'unconfirmed' && sb === 'confirmed') return -1;
  if (sa === 'unconfirmed' && sb === 'changes') return -1;
  if (sa === 'unconfirmed' && sb === 'changesRejected') return -1;
  if (sa === 'changes' && sb === 'confirmed') return -1;
  if (sa === 'changes' && sb === 'changesRejected') return -1;
  if (sa === 'changesRejected' && sb === 'confirmed') return -1;
  return 0;
};

export const some = <T>(arr1: T[], arr2: T[]): boolean => {
  return arr1.some((el) => arr2.includes(el));
};

export const unflatten = (data) => {
  if (Object(data) !== data || Array.isArray(data)) return data;
  // eslint-disable-next-line no-useless-escape
  var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
    resultholder = {};
  for (var p in data) {
    var cur = resultholder,
      prop = '',
      m;
    // eslint-disable-next-line no-cond-assign
    while ((m = regex.exec(p))) {
      cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
      prop = m[2] || m[1];
    }
    cur[prop] = data[p];
  }
  return resultholder[''] || resultholder;
};

export function displayChanges(changes, parentKey = '') {
  const result = [];

  for (const [key, value] of Object.entries(changes)) {
      // Skip "firstCapture"
      if (key === "firstCapture") {
          continue;
      }

      // Capitalize the first letter of the key
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);

      if (typeof value === 'object' && value !== null) {
          // Recursively process nested objects
          result.push(...displayChanges(value, capitalizedKey));
      } else {
          // Add only the capitalized key and its value to the result
          result.push(`${capitalizedKey}: ${value}`);
      }
  }

  return result;
}

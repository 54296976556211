import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DevTool } from '@hookform/devtools';
import { useEffect, useState } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Confirm } from '../../components/forms/Confirm';
import Input from '../../components/forms/Input';
import Content from '../../components/layout/Content';
import Back from '../../components/ui/Back';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Loader from '../../components/ui/Loader';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useUser } from '../../lib/hooks/use-user';
import { useComplaint } from '../../lib/hooks/use-complaints';
import Select from '../../components/forms/Select';

type NewComplaintFormValues = {
  complaintTitle: string;
  complaint: string;
  feature: string;
  userIds: string[];
};

const FEATURES = [
  {
    key: 'complaints',
    label: 'Complaints',
    value: 'complaints',
  },
  {
    key: 'copilot',
    label: 'Co-Pilot',
    value: 'copilot',
  },
  {
    key: 'deliveries',
    label: 'Deliveries',
    value: 'deliveries',
  },
  {
    key: 'Login',
    label: 'login',
    value: 'Login',
  },
  {
    key: 'faq',
    label: 'FAQ',
    value: 'faq',
  },
  {
    key: 'homescreen',
    label: 'Homescreen',
    value: 'homescreen',
  },
  {
    key: 'inventory',
    label: 'Inventory',
    value: 'inventory',
  },
  {
    key: 'leads',
    label: 'Leads',
    value: 'leads',
  },
  {
    key: 'orders',
    label: 'Orders',
    value: 'orders',
  },
  {
    key: 'outlets',
    label: 'Outlets',
    value: 'outlets',
  },
  {
    key: 'route_planning',
    label: 'Route Planning',
    value: 'route_planning',
  },
  {
    key: 'routes',
    label: 'Routes',
    value: 'routes',
  },
  {
    key: 'sales',
    label: 'Direct Sales',
    value: 'sales',
  },
  {
    key: 'sites',
    label: 'Sites',
    value: 'sites',
  },
  {
    key: 'siteVisits',
    label: 'Site Visits',
    value: 'siteVisits',
  },
  {
    key: 'visits',
    label: 'Visits',
    value: 'visits',
  },
  {
    key: 'targets',
    label: 'Targets',
    value: 'targets',
  }
];

const NewComplaint = () => {
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { user } = useUser();
  const { create, loading } = useComplaint();
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors, isValid },
    getValues,
    control,
  } = useForm({ mode: 'all', reValidateMode: 'onChange' });

  const complaintTitleOptions: RegisterOptions = {
    required: 'Please enter a complaint title...',
    minLength: {
      value: 5,
      message: 'Min 5 characters',
    },
  };

  const complaintOptions: RegisterOptions = {
    required: 'Please enter your complaint...',
    minLength: {
      value: 100,
      message: 'Please describe your issue as detailed as possible. Use at least 100 characters',
    },
  };

  const featureOptions: RegisterOptions = {
    required: 'Please select the feature your complain is about.',
  };

  useEffect(() => {
    if (isSubmitSuccessful && !loading) {
      navigate('/complaints', { replace: true });
    }
  }, [isSubmitSuccessful, loading, navigate]);

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(onSubmit)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  const onSubmit: SubmitHandler<NewComplaintFormValues> = (data) => {
    create({
      complaintTitle: data.complaintTitle,
      complaint: data.complaint,
      feature: data.feature,
      userIds: [user.id]
    });
  };

  return (
    <>
      <Loader show={loading} />
      <Confirm
        title="Are you sure you want to create this new complaint?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
        }}
      />
      <Content>
        <Card className="grid grid-cols-12">
          <Back to="/complaints" className="col-span-4" />
          <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="mr-2 text-lh-head-black"
            />
            New complaint
          </div>
          <form
            name="newOrderForm"
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-12 col-span-full mt-2"
          >
            <h2 className="font-bold col-span-12 mt-2 mb-2 text-lh-head-black">
              Complaint Title
            </h2>
            <Input
              name="complaintTitle"
              register={register}
              options={complaintTitleOptions}
              error={errors.complaintTitle}
              type="text"
              className="col-span-full text-lh-text-black"
            />
            <h2 className="font-bold col-span-12 mt-2 mb-2 text-lh-head-black">
              Complaint
            </h2>
            <Input
              name="complaint"
              register={register}
              options={complaintOptions}
              error={errors.complaint}
              type="text"
              className="col-span-full text-lh-text-black"
            />
            <label
              htmlFor="address"
              className="col-span-full font-bold text-lh-head-black"
            >
              Feature
            </label>
            <Select
              register={register}
              name="feature"
              items={FEATURES}
              placeholder="Select a feature..."
              error={errors.feature}
              options={featureOptions}
              className="col-span-12"
            />
          </form>
          <Button
            buttonDisabled={!isValid}
            text="Create complaint"
            color="blue"
            className="col-span-full"
            onClick={() => {
              setShowConfirmation(true);
            }}
          />
          <DevTool control={control} />
        </Card>
      </Content>
    </>
  );
};

export default NewComplaint;

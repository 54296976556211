import { useEffect, useState } from 'react';
import React from 'react';
import Content from '../../components/layout/Content';
import Loader from '../../components/ui/Loader';
import List from '../../components/ui/list/List';
import SearchAndSort from '../../components/ui/SearchAndSort';
import useStore from '../../lib/hooks/use-store';
import { useQuestions, useQuestionsTypesense } from '../../lib/hooks/use-questions';
import { useUser } from '../../lib/hooks/use-user';
import { useAuth } from '../../lib/hooks/use-auth';
import { useProducts } from '../../lib/hooks/use-products';
import Product from '../../components/product/Question';


const Products = React.memo(() => {
  const user = useUser();
  const { typesenseKeyUserIds } = useAuth();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [state, dispatch] = useStore();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertFSA, setShowAlertFSA] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchEnabled, setSearchEnabled] = useState(false);

  const { error, products, loading, load, hasMore } = useProducts({});
  const {
    hasMore: hasMoreTypesense,
    load: loadTypesense,
    loading: loadingTypesense,
    questions: questionsTypesense,
  } = useQuestionsTypesense({
    direction,
    searchText: searchText,
  });


  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error, showAlert, user]);

  return (
    <Content topBar>
    <SearchAndSort
      onCategoryChange={() => {}}
      selectedCategory={state.retailerStatus}
      categories={{}}
      displayCategory={false}
      displaySearch={true}
      searchPlaceHolder="Product"
      className={'mt-4'}
      setSearchText={setSearchText}
      searchDisabled={!searchEnabled}
    />
    {(!searchText || (searchText && searchText.length < 3)) && (
      <List load={load} hasMore={hasMore} loading={loading}>
        {products && products.length > 0 ? (
          products.map((product) => <Product product={product} key={product.id} />)
        ) : (
          <p className="justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 bg-white text-center">
            No {state.status.join(' / ')} products.
          </p>
        )}
      </List>
    )}
    {searchText && searchText.length >= 3 && (
      <>
        <Loader show={loadingTypesense} />
        <List
          load={loadTypesense}
          hasMore={hasMoreTypesense}
          loading={loadingTypesense}
        >
          {questionsTypesense &&
            questionsTypesense.map((question) => (
              <p>Test</p>
            ))}
        </List>
      </>
    )}
  </Content>
  );
});

export default Products;

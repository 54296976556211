import { addDoc, collection } from 'firebase/firestore';
import {
  CreateComplaintData,
} from '../../../../global';
import { firestore } from '../utils/firebase';

interface CreateComplaint {
  (data: CreateComplaintData): Promise<any>;
}

export const createComplaint: CreateComplaint = async (data) => {
  const ref = collection(firestore, 'complaints');
  await addDoc(ref, data);
};

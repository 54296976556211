import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../components/ui/Card';
import Back from '../../../components/ui/Back';
import Content from '../../../components/layout/Content';
import { useComplaint } from '../../../lib/hooks/use-complaints';

type ComplaintDetailsParams = 'id';

const Complaint = () => {
  const { id } = useParams<ComplaintDetailsParams>();
  const {
    loading,
    complaint,
  } = useComplaint(id);

  useEffect(() => {
    console.log(complaint);
  }, [complaint]);

  return (
    <>
      <Content>
        {loading || complaint ? (
          <Card className="grid grid-cols-10">
            <Back to="/complaints" className="col-span-3" />
            <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {complaint && complaint.complaintTitle}
            </div>
            <div className="col-span-12 flex justify-center items-center font-bold text-lh-head-black">
             Complaint
            </div>
            <div className="col-span-12 flex justify-center items-center text-lh-head-black">
              {complaint && complaint.complaint}
            </div>
            <div className="col-span-12 flex justify-center items-center text-lh-head-black">
              {complaint && complaint.answer}
            </div>
          </Card>
        ) : (
          <Card>Complaints not found!</Card>
        )}
      </Content>
    </>
  );
};

export default Complaint;

import { useForm } from 'react-hook-form';
import Content from '../../components/layout/Content';
import Back from '../../components/ui/Back';
import Card from '../../components/ui/Card';
import Input from '../../components/forms/Input';
import Select from '../../components/forms/Select';
import Button from '../../components/ui/Button';
import { useMissingDelivery } from '../../lib/hooks/use-missingDeliveries';
import { useAuth } from '../../lib/hooks/use-auth';
import { Timestamp } from 'firebase/firestore';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import SearchableSelect from '../../components/forms/SearchableSelect';
import { useAssociates } from '../../lib/hooks/use-associates';
import { DevTool } from '@hookform/devtools';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

type FormData = {
  distributor: { label: string; value: string };
  logon: number;
  product: string;
  deliveryDate: Date;
  quantity: number;
};

const PRODUCTS = [
  {
    productName: 'CEM II / B-L 32.5N - CLASSIC-50KG BAG EK',
  },
  {
    productName: 'CEM II / A-L 42.5N - SUPASET-50KG BAG EK',
  },
  {
    productName: 'CEM II / B-L 32.5N - CLASSIC-50KG BAG AH',
  },
  {
    productName: 'CEM II /A-L 42.5N - SUPASET-50KG BAG AH',
  },
  {
    productName: 'CEM II /B-L 32.5R - CLASSIC-50KG BAG K2',
  },
  {
    productName: 'CEM II /A-L 42.5N - SUPASET-50KG BAG K2',
  },
  {
    productName: 'CEM II / B-L 32.5N - CLASSIC-50KG BAG SU',
  },
  {
    productName: 'MORTAR - SUPAFIX C1 20 KG BAG OFF',
  },
  {
    productName: 'MORTAR - SUPAFIX C0 20 KG BAG OFF',
  },
  {
    productName: 'SUPAFIX BASIC TILE ADHESIVE C0 20KG BAG',
  },
  {
    productName: 'CEM II /B-L 42.5R - SUPASET-50KG BAG K2',
  },
  {
    productName: 'CEM II / B-L 42.5N - WATERSHIELD-BAG EK',
  },
  {
    productName: 'POP CEMENT PLASTER IMPORTED 40KG BAG',
  },
];

const NewDelivery: React.FC = () => {
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty, isValid, isSubmitting },
    reset,
  } = useForm<FormData>({
    mode: 'onSubmit',
  });
  const { create } = useMissingDelivery();
  const { id } = useAuth();
  const { error: distributorError, associates: distributors } = useAssociates({
    roles: 'distributor',
    pageSize: 0,
  });

  const onSubmit = (data: FormData) => {
    const deliveryDate = Timestamp.fromDate(
      new Date(`${data.deliveryDate}T12:00:00`)
    );

    let distributorId: string;

    if (checkAccess(['distributor'])) {
      distributorId = id;
    } else {
      distributorId = data.distributor?.value;
    }

    if (!distributorId) {
      toast.error('Distributor not set!');
      return;
    }

    create({
      distributorId,
      LOGONOrderNumber: +data.logon,
      productName: data.product,
      deliveryDate,
      quantity: +data.quantity,
    });
  };

  const onClickCreate = async () => {
    await handleSubmit(onSubmit)();
    navigate('/deliveries', { replace: true });
  };

  const onClickCreateAndAddAnother = async () => {
    await handleSubmit(onSubmit)();
    reset();
  };

  const onClickCancel = () => {
    navigate('/deliveries', { replace: true });
  };

  return (
    <Content>
      <Card className="flex flex-col">
        <div className="grid grid-cols-12 w-full">
          <Back to="/deliveries" className="col-span-3" />
          <h1 className="col-span-6 text-center self-center font-bold">
            Capture Missing Delivery
          </h1>
        </div>
        <form
          name="manualDeliveryForm"
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-12"
        >
          {!checkAccess(['distributor']) && (
            <SearchableSelect
              control={control}
              name="distributor"
              label="Distributor"
              rules={{}}
              placeholder="Select Distributor..."
              values={distributors.map((distributor) => ({
                label: distributor.name,
                value: distributor.id,
              }))}
              className="col-span-full mb-4"
            />
          )}
          <label htmlFor="logon" className="col-span-full">
            LOGON Order Number
          </label>
          <Input
            name="logon"
            register={register}
            options={{
              required: 'Please enter the LOGON Order Number',
              valueAsNumber: true,
            }}
            className="col-span-full"
            placeholder="00000000"
            error={errors.logon}
          />
          <label htmlFor="product" className="col-span-full">
            Product
          </label>
          <Select
            name="product"
            register={register}
            items={PRODUCTS.map(({ productName }) => ({
              key: productName,
              label: productName,
              value: productName,
            }))}
            className="col-span-full"
            placeholder="Select product"
            options={{ required: 'Please select a product' }}
            error={errors.product}
          />
          <label htmlFor="deliveryDate" className="col-span-full">
            Delivery Date
          </label>
          <Input
            name="deliveryDate"
            register={register}
            type="date"
            className="col-span-full"
            options={{ required: 'Please select a delivery date' }}
            error={errors.deliveryDate}
          />
          <label htmlFor="quantity" className="col-span-full">
            Quantity (in tons)
          </label>
          <Input
            name="quantity"
            register={register}
            type="number"
            className="col-span-full"
            options={{
              required: 'Please enter the quantity of the delivery (in tons)',
              valueAsNumber: true,
              min: { value: 0, message: 'Quantity must be greater than 0' },
              max: { value: 50, message: 'Quantity must be less than 50' },
            }}
            error={errors.quantity}
          />
        </form>
        <div className="w-full flex gap-4">
          <Button
            color="blue"
            buttonDisabled={!isValid || !isDirty || isSubmitting}
            onClick={onClickCreate}
          >
            Create
          </Button>
          <Button
            color="secondary"
            buttonDisabled={!isValid || !isDirty || isSubmitting}
            onClick={onClickCreateAndAddAnother}
          >
            Create and add another
          </Button>
          <Button
            className="self-end"
            color="secondary"
            onClick={onClickCancel}
          >
            Cancel
          </Button>
        </div>
      </Card>
      <DevTool control={control} />
    </Content>
  );
};

export default NewDelivery;

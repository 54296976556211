import { addDoc, collection } from 'firebase/firestore';
import { CreateMissingDeliveryData } from '../../../../global';
import { firestore } from '../utils/firebase';

export const createMissingDelivery = async (
  data: CreateMissingDeliveryData
) => {
  const ref = collection(firestore, `missingDeliveries`);
  return addDoc(ref, data);
};

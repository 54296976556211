import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Input from '../../../components/forms/Input';
import { useState } from 'react';

type Props = {
  editable: boolean;
  setBlockmakerConsumptionData: any;
  setBlockmakerConsumptionDirty: any;
  setBlockmakerConsumptionIsValid: any;
  blockmakerConsumptionData: {
    lapConsumed: number;
    com1Consumed: number;
    com2Consumed: number;
    com3Consumed: number;
    otherConsumed: number;
    otherConsumedProduct: string;
    blocksInventory: number;
    blocksSold: number;
  };
};

const SiteVisitStepBlockmakerConsumption = forwardRef((props: Props, ref) => {
  const [formValid, setFormValid] = useState(null);
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: { ...props.blockmakerConsumptionData },
    mode: 'all',
  });

  const lapConsumedAmount = useWatch({
    control,
    name: 'lapConsumed',
  });

  const com1ConsumedAmount = useWatch({
    control,
    name: 'com1Consumed',
  });

  const com2ConsumedAmount = useWatch({
    control,
    name: 'com2Consumed',
  });

  const com3ConsumedAmount = useWatch({
    control,
    name: 'com3Consumed',
  });

  const otherConsumedAmount = useWatch({
    control,
    name: 'otherConsumed',
  });

  const otherConsumedProduct = useWatch({
    control,
    name: 'otherConsumedProduct',
  });

  const blocksInventoryAmount = useWatch({
    control,
    name: 'blocksInventory',
  })

  const blocksSoldAmount = useWatch({
    control,
    name: 'blocksSold',
  })

  const amountOptions: RegisterOptions = {
    valueAsNumber: true,
    required: 'Amount is required!',      
    max: {
      value: 10000,
      message: 'Maximum amount is 10\'000',
    },
  };

  const minAmountOptions: RegisterOptions = {
    valueAsNumber: true,
    required: 'Amount is required!',      
    min: {
      value: 0,
      message: 'Minimum amount is 0',
    }
  };

  const otherConsumedProductOptions: RegisterOptions = {
    required: 'Please specfiy!',      
  };

  useEffect(() => {
    if(
      isValid && 
      lapConsumedAmount >= 0 &&
      com1ConsumedAmount >= 0 &&
      com2ConsumedAmount >= 0 &&
      com3ConsumedAmount >= 0 &&
      (
        (otherConsumedAmount > 0 && otherConsumedProduct) ||
        otherConsumedAmount === 0
      ) &&
      blocksInventoryAmount >= 0 &&
      blocksSoldAmount >= 0
    ) {
      props.setBlockmakerConsumptionDirty(true);
      props.setBlockmakerConsumptionIsValid(true);
      setFormValid(true);
    } else {
      props.setBlockmakerConsumptionDirty(false);
      props.setBlockmakerConsumptionIsValid(false);
      setFormValid(false);
    }

   
  }, [isValid, lapConsumedAmount, com1ConsumedAmount, com2ConsumedAmount, com3ConsumedAmount, otherConsumedAmount, otherConsumedProduct, blocksInventoryAmount, blocksSoldAmount]);

  useImperativeHandle(ref, () => ({
    saveBlockmakerConsumption() {
      doSaveBlockmakersData();
    },
  }));

  const doSaveBlockmakersData = () => {
    // get values from form and state
    const values = {
      lapConsumed: lapConsumedAmount,
      com1Consumed: com1ConsumedAmount,
      com2Consumed: com2ConsumedAmount,
      com3Consumed: com3ConsumedAmount,
      otherConsumed: otherConsumedAmount,
      otherConsumedProduct: otherConsumedProduct ?? null,
      blocksInventoryAmount: blocksInventoryAmount,
      blocksSoldAmount: blocksSoldAmount,
    };

    props.setBlockmakerConsumptionData(values);
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <p className={'col-span-12 text-center mt-4 font-semibold'}>
            Blockmaker Consumption (Within a week)
          </p>
          <p className={'col-span-6 text-left mt-2 mb-4 font-semibold'}>
            Product
          </p>
          <p className={'col-span-6 text-center mt-2 mb-4 font-semibold'}>
            Amount (bags)
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            LAP Consumed
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="lapConsumed"
            placeholder="0"
            error={errors.lapConsumed}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 1 Consumed
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com1Consumed"
            placeholder="0"
            error={errors.com1Consumed}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 2 Consumed
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com2Consumed"
            placeholder="0"
            error={errors.com2Consumed}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
           <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Com 3 Consumed
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="com3Consumed"
            placeholder="0"
            error={errors.com3Consumed}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
            <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            Other Consumed
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="otherConsumed"
            placeholder="0"
            error={errors.otherConsumed}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          {otherConsumedAmount > 0 && (
            <>
              <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
              Other Products
            </p>
              <Input
                register={register}
                name="otherConsumedProduct"
                error={errors.otherConsumedProduct}
                options={otherConsumedProductOptions}
                placeholder="Please specify the products..."
                className="col-span-6"
              />
            </>
          )}
          <p className={'col-span-12 text-center mt-4 mb-4 font-semibold'}>
            Inventory and Sales (Within a week)
          </p>
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            No. of Blocks in Inventory 
          </p>
          <Input
            register={register}
            options={minAmountOptions}
            type="number"
            name="blocksInventory"
            placeholder="0"
            error={errors.blocksInventory}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-6 text-left mb-2 font-normal mt-1'}>
            No. of Blocks Sold 
          </p>
          <Input
            register={register}
            options={minAmountOptions}
            type="number"
            name="blocksSold"
            placeholder="0"
            error={errors.blocksSold}
            disabled={props.editable}
            className={'col-span-6  pr-2 text-right mx-1 mb-2 font-normal'}
          />
        </div>
        {!formValid && (
          <p
            className={
              'col-span-12 pr-2 text-center text-red-500 mx-1 mb-2 font-normal'
            }
          >
            Please enter values for each field. If it is 0, enter 0.
          </p>
        )}
      </div>
    </>
  );
});

export default SiteVisitStepBlockmakerConsumption;

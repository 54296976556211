import {
  faAngleLeft,
  faAngleRight,
  faExclamationTriangle,
  faSpinner,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Loader from '../../../components/ui/Loader';
import { useVisit, useVisits } from '../../../lib/hooks/use-visits';
import VisitStep0 from './VisitStep0';
import VisitStep1 from './VisitStep1';
import VisitStep2 from './VisitStep2';
import VisitStep3 from './VisitStep3';
import VisitStep4 from './VisitStep4';
import Content from '../../../components/layout/Content';
import Card from '../../../components/ui/Card';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import { useOutlet } from '../../../lib/hooks/use-outlets';
import { Timestamp } from 'firebase/firestore';
import { useUser } from '../../../lib/hooks/use-user';
import dayjs from 'dayjs';
import { getDistance } from 'geolib';
import { useGeolocation } from '../../../lib/utils/useGeolocation';
import ButtonBottomRight from '../../../components/ui/ButtonBottomRight';
import numeral from 'numeral';
import useInterval from '../../../lib/utils/useInterval';
import VisitStepSellIn from './VisitStepSellIn';
import VisitStepBlockmakers from './VisitStepBlockmakers';
import VisitStepRequestPOSM from './VisitStepRequestPOSM';
import { usePOSMRequest } from '../../../lib/hooks/use-posmRequests';

type VisitParams = 'id';

interface CustomHTMDIVElement extends HTMLDivElement {
  saveStock?: Function;
  savePOSM?: Function;
  saveRequestPOSM?: Function;
  saveSellIn?: Function;
  saveBlockmakersData?: Function;
}

const Visit = () => {
  const RANGE = 200;
  const FINAL_STEP = 6;
  const MIN_ACCURACY = 100;
  // SETUP: Change business hours here:
  const START = 8;
  const END_HOUR = 18;
  const END_MIN = 30;

  const { id } = useParams<VisitParams>();
  const { user } = useUser();
  const navigate = useNavigate();
  const [visitStep, setVisitStep] = useState<number>(0);
  const [outletId, setOutletId] = useState<string>(null);
  const [showAlertVisit, setShowAlertVisit] = useState<boolean>(false);
  const [showAlertOutlet, setShowAlertOutlet] = useState<boolean>(false);
  const [showAlertLocation, setShowAlertLocation] = useState<boolean>(false);
  const [stockLevel, setStockLevel] = useState(null);
  const [posm, setPOSM] = useState(null);
  const [requestedPOSM, setRequestedPOSM] = useState(null);
  const [sellInOut, setSellInOut] = useState(null);
  const [blockmakersData, setBlockmakersData] = useState(null);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const visitStep1Ref = useRef<CustomHTMDIVElement>(null);
  const visitStep2Ref = useRef<CustomHTMDIVElement>(null);
  const visitStepRequestPOSMRef = useRef<CustomHTMDIVElement>(null);
  const visitStepSellInRef = useRef<CustomHTMDIVElement>(null);
  const visitStepBlockmakersRef = useRef<CustomHTMDIVElement>(null);
  const [startedAt, setStartedAt] = useState(null);
  const [startedDistance, setStartedDistance] = useState<number>(null);
  const [visitId, setVisitId] = useState('');
  const [stockIsDirty, setStockIsDirty] = useState<boolean>(false);
  const [stockIsValid, setStockIsValid] = useState<boolean>(false);
  const [sellInIsDirty, setSellInIsDirty] = useState<boolean>(false);
  const [sellInIsValid, setSellInIsValid] = useState<boolean>(false);
  const [blockmakersIsDirty, setBlockmakersIsDirty] = useState<boolean>(false);
  const [blockmakersIsValid, setBlockmakersIsValid] = useState<boolean>(false);
  const [posmIsValid, setPOSMIsValid] = useState<boolean>(false);
  const [requestPOSMIsValid, setRequestPOSMIsValid] = useState<boolean>(false);
  const [showReloadButton, setShowReloadButton] = useState<boolean>(false);
  const [outletLocationLoading, setOutletLocationLoading] =
    useState<boolean>(false);
  const [outletLocationCaptured, setOutletLocationCaptured] =
    useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [countdown, setCountdown] = useState<number>(60);
  const {
    positionError,
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocation({
    userDecisionTimeout: 10000,
    watchPosition: true,
  });
  const {
    outlet,
    loading: outletLoading,
    error: outletError,
    update: outletUpdate,
  } = useOutlet(outletId);
  const {
    visit,
    loading: visitLoading,
    error: visitError,
    add,
  } = useVisit(visitId);
  const { visits, loading } = useVisits({
    day: dayjs().date(),
    month: dayjs().month() + 1,
    year: dayjs().year(),
  });
  const {
    loading: posmRequestLoading,
    posmRequest,
    create: createPOSMRequest,
    update: updatePOSMRequest
  } = usePOSMRequest(outletId);

  const locationErrorMessage = useMemo(() => {
    switch (positionError?.code) {
      case 1: // PERMISSION_DENIED
        return 'Please enable location services following the user manual: https://www.mcomtech.ch/guide';
      case 2: // POSITION_UNAVAILABLE
        return 'Location services are unavailable. Please try again later.';
      case 3: // TIMEOUT
        return 'Location services timed out. Please try again later.';
      case undefined:
        return undefined;
      default:
        return 'Unknown error';
    }
  }, [positionError]);

  const distance = useMemo(() => {
    if (coords && outlet?.location) {
      return getDistance(coords, outlet.location);
    }
    return undefined;
  }, [coords, outlet]);

  const distanceErrorMessage = useMemo(() => {
    if (distance > RANGE) {
      const distanceString =
        distance > 1000
          ? numeral(distance).divide(1000).format('0.0') + ' kilometers'
          : distance + ' meters';
      setShowReloadButton(true);
      return `You are ${distanceString} away from the outlet. You have to be within ${RANGE} meters to check in.`;
    }
    return undefined;
  }, [distance, RANGE]);

  const countDownDelay = useMemo(
    () => (countdown <= 0 ? null : 1000),
    [countdown]
  );

  const alreadyVisited = useMemo(() => {
    if (loading) return true;
    if (visits) {
      return visits.some((v) => v.outlet.id === outletId);
    }
    return false;
  }, [loading, visits, outletId]);

  const isBusinessHours = useMemo(() => {
    const now = dayjs();
    // Setup opening and closing times to 0800 and 1800 between Monday and Saturday respectively
    const opening = dayjs().set('hour', START).set('minute', 0);
    const closing = dayjs().set('hour', END_HOUR).set('minute', END_MIN);

    // Check if it is Sunday
    return now.day() === 0
      ? false
      : now.isAfter(opening) && now.isBefore(closing);
  }, []);

  const waitingForAccuracy = useMemo(() => {
    if (
      countdown === 0 ||
      !isGeolocationAvailable ||
      !isGeolocationEnabled ||
      positionError
    ) {
      return false;
    }
    return !coords || coords.accuracy > MIN_ACCURACY;
  }, [
    coords,
    MIN_ACCURACY,
    countdown,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  ]);

  useInterval(() => {
    setCountdown(countdown - 1);
  }, countDownDelay);

  useEffect(() => {
    setShowLoader(visitLoading || outletLoading || outletLocationLoading);
  }, [outletLoading, outletLocationLoading, visitLoading]);

  useEffect(() => {
    if (id && id.includes('_')) {
      setOutletId(id.split('_')[1]);
    } else if (id) {
      setVisitId(id);
      setVisitStep(1);
    }
  }, [id]);

  useEffect(() => {
    if (visit) {
      setOutletId(visit.outlet.id);
      setStockLevel(visit.stockLevel);
      setPOSM(visit.posm);
    }
  }, [visit]);

  useEffect(() => {
    if (positionError) {
      setShowAlertLocation(true);
    }
  }, [positionError]);

  const handleNext = () => {
    if (visitStep !== FINAL_STEP) {
      setVisitStep(visitStep + 1);
    }

    if (visitStep === 1) {
      if (visitStep1Ref && visitStep1Ref.current) {
        visitStep1Ref.current.saveStock();
      }
    }

    if (visitStep === 2) {
      if (visitStep2Ref && visitStep2Ref.current) {
        visitStep2Ref.current.savePOSM();
      }
    }

    if (visitStep === 3) {
      if (visitStepRequestPOSMRef && visitStepRequestPOSMRef.current && !visit) {
        visitStepRequestPOSMRef.current.saveRequestPOSM();
      }
    }

    if (visitStep === 4) {
      if (visitStepSellInRef && visitStepSellInRef.current && !visit) {
        visitStepSellInRef.current.saveSellIn();
      }
    }

    if (visitStep === 4) {
      if (visitStepBlockmakersRef && visitStepBlockmakersRef.current && !visit) {
        visitStepBlockmakersRef.current.saveBlockmakersData();
      }
    }
  };

  const handlePrevious = () => {
    if (visitStep !== 0) {
      setVisitStep(visitStep - 1);
    }

    if (visitStep === 1) {
      if (visitStep1Ref && visitStep1Ref.current && !visit) {
        visitStep1Ref.current.saveStock();
      }
    }

    if (visitStep === 2) {
      if (visitStep2Ref && visitStep2Ref.current && !visit) {
        visitStep2Ref.current.savePOSM();
      }
    }

    if (visitStep === 3) {
      if (visitStepRequestPOSMRef && visitStepRequestPOSMRef.current && !visit) {
        visitStepRequestPOSMRef.current.saveRequestPOSM();
      }
    }

    if (visitStep === 4) {
      if (visitStepSellInRef && visitStepSellInRef.current && !visit) {
        visitStepSellInRef.current.saveSellIn();
      }
    }

    if (visitStep === 4) {
      if (visitStepBlockmakersRef && visitStepBlockmakersRef.current && !visit) {
        visitStepBlockmakersRef.current.saveBlockmakersData();
      }
    }
  };

  const visitStep1Props = {
    setStockLevel: setStockLevel,
    stockLevel: stockLevel,
  };

  const visitStep2Props = {
    setPOSM: setPOSM,
    posm: posm,
  };

  const visitStepRequestPOSMProps = {
    setRequestPOSM: setRequestedPOSM,
    requestedPOSM: requestedPOSM,
    posm,
    posmRequestLoading,
    posmRequest
  }

  const visitStepSellInProps = {
    setSellIn: setSellInOut,
    sellInOut: sellInOut,
  }

  const visitStepBlockmakersProps = {
    setBlockmakersData: setBlockmakersData,
    blockmakersData: blockmakersData
  }

  const addVisit = async () => {
    await add({
      stockLevel: stockLevel,
      posm: posm,
      sellInOut: sellInOut,
      blockmakersData: blockmakersData,
      location: coords,
      distance,
      orderPlaced: orderPlaced,
      createdBy: {
        id: user.id,
        name: user.name,
        phone: user.phone,
      },
      sop: {
        email: user.sop.email,
        id: user.sop.id,
        name: user.sop.name,
        phone: user.sop.phone,
      },
      outlet: outlet,
      startedDistance,
      startedAt: startedAt,
      finishedAt: Timestamp.now(),
      day: dayjs().date(),
      month: dayjs().month() + 1,
      year: dayjs().year(),
    });

    navigate('/visits');
  };

  const saveVisitAndPOSMRequest = async () => {
    if(posmRequest) {
      await updatePOSMRequest(requestedPOSM);
    } else {
      await createPOSMRequest(requestedPOSM)
    }
    await addVisit();
  }

  const saveNewLocation = () => {
    setOutletLocationLoading(true);
    if (coords) {
      outletUpdate({ location: coords, firstCapture: true });
      setOutletLocationCaptured(true);
    }
    setOutletLocationLoading(false);
  };

  const reload = () => {
    setCountdown(60);
  };

  const checkIn = () => {
    setStartedDistance(distance);
    setStartedAt(Timestamp.now());
    setShowReloadButton(false);
    handleNext();
  };

  return (
    <Content>
      <Loader show={showLoader} />
      {showReloadButton && (
        <ButtonBottomRight
          onClick={reload}
          blocked={false}
          color="red"
          arialabel="reload"
          icon={faSync}
        />
      )}
      <Alert
        message={visitError && 'Error while loading visit!'}
        open={showAlertVisit}
        setOpen={(open) => setShowAlertVisit(open)}
        title="Error"
      />
      <Alert
        message={outletError && 'Error while loading outlet!'}
        open={showAlertOutlet}
        setOpen={(open) => setShowAlertOutlet(open)}
        title="Error"
      />
      <Alert
        message={positionError && locationErrorMessage}
        open={showAlertLocation}
        setOpen={(open) => setShowAlertLocation(open)}
        title="Location Error"
      />
      {visitLoading || outlet ? 
        outlet?.active === true ? (
        <Card>
          <div className="grid grid-cols-12">
            {(visitStep === 0 || visit) && (
              <Back to="/visits" className=" col-span-3" />
            )}
            <div className="grid grid-cols-9 col-span-12 gap-2">
              <div className="grid grid-rows-2 col-span-9 text-center">
                <h2 className="font-bold row-span-1 -mt-4">
                  {outlet && outlet.name}
                </h2>
              </div>
              {!visit && (
                <VisitStep0
                  currentStep={visitStep}
                  captureOutletLocation={saveNewLocation}
                  location={coords}
                  outlet={outlet}
                  outletLocationCaptured={outletLocationCaptured}
                  outletLocationLoading={false}
                />
              )}
              {visitStep === 1 && (
                <VisitStep1
                  ref={visitStep1Ref}
                  {...visitStep1Props}
                  editable={visit ? true : false}
                  setStockIsDirty={setStockIsDirty}
                  setStockIsValid={setStockIsValid}
                />
              )}
              {visitStep === 2 && (
                <VisitStep2
                  setPOSMIsValid={setPOSMIsValid}
                  ref={visitStep2Ref}
                  {...visitStep2Props}
                  editable={visit ? true : false}
                />
              )}
              {visitStep === 3 && (
                <VisitStepRequestPOSM
                  setRequestPOSMIsValid={setRequestPOSMIsValid}
                  ref={visitStepRequestPOSMRef}
                  {...visitStepRequestPOSMProps}
                  editable={visit ? true : false}
                />
              )}
              {visitStep === 4 && (outlet.channel === 'container' || outlet.channel === 'palleter' || outlet.channel === 'tile_seller' || outlet.channel === 'neighborhood_shop') && (
                <VisitStepSellIn
                  ref={visitStepSellInRef}
                  {...visitStepSellInProps}
                  editable={visit ? true : false}
                  setSellInDirty={setSellInIsDirty}
                  setSellInIsValid={setSellInIsValid}
                />
              )}
              {visitStep === 4 && (outlet.channel === 'blockmaker' || outlet.channel === 'blockmaker_retailer') && (
                <VisitStepBlockmakers
                  ref={visitStepBlockmakersRef}
                  {...visitStepBlockmakersProps}
                  editable={visit ? true : false}
                  setBlockmakersDirty={setBlockmakersIsDirty}
                  setBlockmakersIsValid={setBlockmakersIsValid}
                />
              )}
              <VisitStep3
                currentStep={visitStep}
                outlet={outlet}
                setOrderPlaced={setOrderPlaced}
              />
              <VisitStep4
                currentStep={visitStep}
                changeStep={(step) => setVisitStep(step)}
                outlet={outlet}
                step1Done={stockLevel ? true : false}
                step2Done={
                  posm && !Object.values(posm).every((v) => v === false)
                    ? true
                    : false
                }
                step3Done={orderPlaced}
                sellInOutDone={sellInOut ? true : false}
                consumptionDone={blockmakersData ? true : false}
              />
              {visitStep === 0 &&
                (outlet?.location || outlet?.changes?.location) &&
                'address' in outlet && (
                  <>
                    {waitingForAccuracy && (
                      <div className="col-span-7 col-start-2 flex flex-row justify-center">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="animate-spin h-5 w-5 mr-3"
                        ></FontAwesomeIcon>
                        <p>
                          Waiting for device location...{countdown} seconds
                          left.
                        </p>
                      </div>
                    )}
                    {!waitingForAccuracy && distanceErrorMessage && (
                      <div className="col-span-7 col-start-2 flex flex-row justify-center text-red-600 px-3">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="mr-3 mt-1"
                        ></FontAwesomeIcon>
                        <p>{distanceErrorMessage}</p>
                      </div>
                    )}
                    <Button
                      buttonDisabled={
                        !isBusinessHours ||
                        alreadyVisited ||
                        waitingForAccuracy ||
                        !!positionError ||
                        !!distanceErrorMessage
                      }
                      className="col-span-7 col-start-2"
                      onClick={checkIn}
                      text={'Click to Check-In'}
                    />
                    {isBusinessHours === false && !alreadyVisited && (
                      <div className="col-span-7 col-start-2 flex flex-row justify-center text-red-600 px-3">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="mr-3 mt-1"
                        ></FontAwesomeIcon>
                        <p>
                          It is outside of business hours. Please try to
                          check-in between{' '}
                          {dayjs().hour(START).minute(0).format('HH:mm')} and{' '}
                          {dayjs()
                            .hour(END_HOUR)
                            .minute(END_MIN)
                            .format('HH:mm')}
                          .
                        </p>
                      </div>
                    )}
                    {!loading && alreadyVisited && (
                      <div className="col-span-7 col-start-2 flex flex-row justify-center text-red-600 px-3">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="mr-3 mt-1"
                        ></FontAwesomeIcon>
                        <p>You have already visited this outlet today.</p>
                      </div>
                    )}
                  </>
                )}
              {visitStep === FINAL_STEP && (
                <Button
                  buttonDisabled={
                    alreadyVisited ||
                    !(
                      (stockLevel ? true : false) &&
                      (posm && !Object.values(posm).every((v) => v === false)
                        ? true
                        : false)
                    )
                  }
                  className="col-span-7 col-start-2"
                  onClick={() => saveVisitAndPOSMRequest()}
                  text={'Click to Check-Out'}
                />
              )}
              {((visitStep !== 1 && visitStep !== 0 && !visit) ||
                (visit &&
                  visit.stockLevel &&
                  visitStep !== 0 &&
                  visitStep !== 1)) && (
                <button
                  className="col-span-4 mt-12 font-semibold"
                  onClick={() => handlePrevious()}
                >
                  <FontAwesomeIcon icon={faAngleLeft} /> Go back
                </button>
              )}
              {((visitStep !== 0 &&
                visitStep !== FINAL_STEP &&
                visitStep !== 1 &&
                visitStep !== 2 &&
                visitStep !== 3 && 
                visitStep !== 4 && 
                !visit) ||
                (visitStep !== 0 &&
                  visitStep !== 3 &&
                  visitStep !== 4 && 
                  visitStep !== 2 &&
                  visit) ||
                (visitStep === 1 && stockIsDirty && stockIsValid) ||
                (visitStep === 2 && posmIsValid) ||
                (visitStep === 3 && requestPOSMIsValid) ||
                (visitStep === 4 && (outlet.channel === 'container' || outlet.channel === 'palleter' || outlet.channel === 'tile_seller' || outlet.channel === 'neighborhood_shop') && sellInIsDirty && sellInIsValid) ||
                (visitStep === 4 && (outlet.channel === 'blockmaker' || outlet.channel === 'blockmaker_retailer') && blockmakersIsDirty && blockmakersIsValid)) && (
                <button
                  className={
                    'col-span-4 col-start-5 text-right mt-12 font-semibold'
                  }
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Next step <FontAwesomeIcon icon={faAngleRight} />
                </button>
              )}
              {visitStep === 1 &&
                !visit &&
                ((!stockIsDirty && !stockIsValid && stockLevel == null) ||
                  (!stockIsDirty &&
                  stockLevel &&
                  !Object.values(stockLevel).every((v) => !isNaN(v as number))
                    ? true
                    : false)) && (
                  <button
                    className={
                      'col-span-4 col-start-5 text-right mt-12 font-semibold'
                    }
                    onClick={() => {
                      handleNext();
                    }}
                  >
                    No stock <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                )}
            </div>
          </div>
        </Card>
      ): (
        <Card>Outlet was deactivated! Please remove this outlet from your route plan or contact your SOP to do it for you. If this is a mistake, please contact your SEM.</Card>
      ) : (
        <Card>Outlet not found!</Card>
      )}
    </Content>
  );
};

export default Visit;

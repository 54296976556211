import dayjs from 'dayjs';
import { useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import { useOrder } from '../../../lib/hooks/use-orders';
import AcceptOrder from '../../../components/orders/AcceptOrder';
import RejectOrder from '../../../components/orders/RejectOrder';
import FulfillOrder from '../../../components/orders/FulfillOrder';
import Loader from '../../../components/ui/Loader';
import Back from '../../../components/ui/Back';
import Edit from '../../../components/ui/Edit';
import Button from '../../../components/ui/Button';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import Content from '../../../components/layout/Content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faUserCircle,
  faClock,
  faCheckCircle,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { faCircleCheck, faCirclePlus, faCircleXmark, faTruck, faWarning } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../lib/hooks/use-auth';
import CancelOrder from '../../../components/orders/CancelOrder';

type OrderDetailsParams = 'id';

const Order = () => {
  const { id } = useParams<OrderDetailsParams>();
  const { id: userId } = useAuth();
  const checkAccess = useCheckAccess();
  const navigate = useNavigate();
  const {
    accept,
    cancel,
    error,
    fulfill,
    loading,
    order,
    partialFulfill,
    reject,
    tier2Fulfill,
  } = useOrder(id);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showFulfill, setShowFulfill] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showFulfillPartially, setShowFulfillPartially] = useState(false);
  const [showFulfillTier2, setShowFulfillTier2] = useState(false);
  const [displayEditButton, setDisplayEditButton] = useState(false);
  const [displayComment, setDisplayComment] = useState(false);
  const [displayAcceptButton, setDisplayAcceptButton] = useState(false);
  const [displayRejectButton, setDisplayRejectButton] = useState(false);
  const [displayFulfillButton, setDisplayFulfillButton] = useState(false);
  const [displayCancelButton, setDisplayCancelButton] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (order) {
      switch (order.status) {
        case 'pending':
          setDisplayEditButton(true);
          setDisplayAcceptButton(true);
          setDisplayRejectButton(true);
          setDisplayCancelButton(order.createdBy.id === userId);
          break;
        case 'accepted':
          setDisplayFulfillButton(true);
          setDisplayComment(true);

          break;
        case 'fulfilled':
          setDisplayComment(true);

          break;
        case 'rejected':
          setDisplayComment(true);

          break;
        default:
          break;
      }
    }
  }, [order, userId]);

  const handleAccept = async (data) => {
    await accept(data.comment);
    navigate('/orders', { replace: true });
  };
  const handleReject = async (data) => {
    await reject(data.comment);
    navigate('/orders', { replace: true });
  };
  const handleCancel = async (data) => {
    await cancel(data.comment);
    navigate('/orders', { replace: true });
  };
  const handleFulfill = async (data) => {
    const classic = isNaN(data.classic) ? 0 : data.classic;
    const supaset = isNaN(data.supaset) ? 0 : data.supaset;
    const supafixc0 = isNaN(data.supafixc0) ? 0 : data.supafixc0;
    const supafixc1 = isNaN(data.supafixc1) ? 0 : data.supafixc1;
    const watershield = isNaN(data.watershield) ? 0 : data.watershield;
    const pop = isNaN(data.pop) ? 0 : data.pop;
    //tempProduct2 const tempProduct2 = isNaN(data.tempProduct2) ? 0 : data.tempProduct2;
    //tempProduct3 const tempProduct3 = isNaN(data.tempProduct3) ? 0 : data.tempProduct3;
    //tempProduct4 const tempProduct4 = isNaN(data.tempProduct4) ? 0 : data.tempProduct4;

    const productsDelivered = {
      classic:
        order.productsDelivered && order.productsDelivered.classic
          ? order.productsDelivered.classic + classic
          : classic,
      supaset:
        order.productsDelivered && order.productsDelivered.supaset
          ? order.productsDelivered.supaset + supaset
          : supaset,
      supafixc0:
        order.productsDelivered && order.productsDelivered.supafixc0
          ? order.productsDelivered.supafixc0 + supafixc0
          : supafixc0,
      supafixc1:
        order.productsDelivered && order.productsDelivered.supafixc1
          ? order.productsDelivered.supafixc1 + supafixc1
          : supafixc1,
      watershield:
        order.productsDelivered && order.productsDelivered.watershield
          ? order.productsDelivered.watershield + watershield
          : watershield,
      
      pop:
        order.productsDelivered && order.productsDelivered.pop
          ? order.productsDelivered.pop + pop
          : pop,
      
      /* tempProduct2
      tempProduct2:
        order.productsDelivered && order.productsDelivered.tempProduct2
          ? order.productsDelivered.tempProduct2 + tempProduct2
          : tempProduct2,
      tempProduct2 */
      /* tempProduct3
      tempProduct3:
        order.productsDelivered && order.productsDelivered.tempProduct3
          ? order.productsDelivered.tempProduct3 + tempProduct3
          : tempProduct3,
      tempProduct3 */
      /* tempProduct4
      tempProduct4:
        order.productsDelivered && order.productsDelivered.tempProduct4
          ? order.productsDelivered.tempProduct4 + tempProduct4
          : tempProduct4,
      tempProduct4 */
    };
    await fulfill(productsDelivered, data.deliveredToConstructionSite === 'true');
    navigate('/orders', { replace: true });
  };

  const handleFulfillPartially = async (data) => {
    const classic = isNaN(data.classic) ? 0 : data.classic;
    const supaset = isNaN(data.supaset) ? 0 : data.supaset;
    const supafixc0 = isNaN(data.supafixc0) ? 0 : data.supafixc0;
    const supafixc1 = isNaN(data.supafixc1) ? 0 : data.supafixc1;
    const watershield = isNaN(data.watershield) ? 0 : data.watershield;
    const pop = isNaN(data.pop) ? 0 : data.pop;
    //tempProduct2 const tempProduct2 = isNaN(data.tempProduct2) ? 0 : data.tempProduct2;
    //tempProduct3 const tempProduct3 = isNaN(data.tempProduct3) ? 0 : data.tempProduct3;
    //tempProduct4 const tempProduct4 = isNaN(data.tempProduct4) ? 0 : data.tempProduct4;

    const productsDelivered = {
      classic:
        order.productsDelivered && order.productsDelivered.classic
          ? order.productsDelivered.classic + classic
          : classic,
      supaset:
        order.productsDelivered && order.productsDelivered.supaset
          ? order.productsDelivered.supaset + supaset
          : supaset,
      supafixc0:
        order.productsDelivered && order.productsDelivered.supafixc0
          ? order.productsDelivered.supafixc0 + supafixc0
          : supafixc0,
      supafixc1:
        order.productsDelivered && order.productsDelivered.supafixc1
          ? order.productsDelivered.supafixc1 + supafixc1
          : supafixc1,
      watershield:
        order.productsDelivered && order.productsDelivered.watershield
          ? order.productsDelivered.watershield + watershield
          : watershield,
      
      pop:
        order.productsDelivered && order.productsDelivered.pop
          ? order.productsDelivered.pop + pop
          : pop,
      
      /* tempProduct2
      tempProduct2:
        order.productsDelivered && order.productsDelivered.tempProduct2
          ? order.productsDelivered.tempProduct2 + tempProduct2
          : tempProduct2,
      tempProduct2 */
      /* tempProduct3
      tempProduct3:
        order.productsDelivered && order.productsDelivered.tempProduct3
          ? order.productsDelivered.tempProduct3 + tempProduct3
          : tempProduct3,
      tempProduct3 */
      /* tempProduct4
      tempProduct4:
        order.productsDelivered && order.productsDelivered.tempProduct4
          ? order.productsDelivered.tempProduct4 + tempProduct4
          : tempProduct4,
      tempProduct4 */
    };

    await partialFulfill(productsDelivered, data.deliveredToConstructionSite === 'true');
    navigate('/orders', { replace: true });
  };

  const handleFulfillPartiallyTier2 = async (data) => {
    const classic = isNaN(data.classic) ? 0 : data.classic;
    const supaset = isNaN(data.supaset) ? 0 : data.supaset;
    const supafixc0 = isNaN(data.supafixc0) ? 0 : data.supafixc0;
    const supafixc1 = isNaN(data.supafixc1) ? 0 : data.supafixc1;
    const watershield = isNaN(data.watershield) ? 0 : data.watershield;
    const pop = isNaN(data.pop) ? 0 : data.pop;
    //tempProduct2 const tempProduct2 = isNaN(data.tempProduct2) ? 0 : data.tempProduct2;
    //tempProduct3 const tempProduct3 = isNaN(data.tempProduct3) ? 0 : data.tempProduct3;
    //tempProduct4 const tempProduct4 = isNaN(data.tempProduct4) ? 0 : data.tempProduct4;

    const productsDelivered = {
      classic:
        order.productsDelivered && order.productsDelivered.classic
          ? order.productsDelivered.classic + classic
          : classic,
      supaset:
        order.productsDelivered && order.productsDelivered.supaset
          ? order.productsDelivered.supaset + supaset
          : supaset,
      supafixc0:
        order.productsDelivered && order.productsDelivered.supafixc0
          ? order.productsDelivered.supafixc0 + supafixc0
          : supafixc0,
      supafixc1:
        order.productsDelivered && order.productsDelivered.supafixc1
          ? order.productsDelivered.supafixc1 + supafixc1
          : supafixc1,
      watershield:
        order.productsDelivered && order.productsDelivered.watershield
          ? order.productsDelivered.watershield + watershield
          : watershield,
      
      pop:
        order.productsDelivered && order.productsDelivered.pop
          ? order.productsDelivered.pop + pop
          : pop,
      
      /* tempProduct2
      tempProduct2:
        order.productsDelivered && order.productsDelivered.tempProduct2
          ? order.productsDelivered.tempProduct2 + tempProduct2
          : tempProduct2,
      tempProduct2 */
      /* tempProduct3
      tempProduct3:
        order.productsDelivered && order.productsDelivered.tempProduct3
          ? order.productsDelivered.tempProduct3 + tempProduct3
          : tempProduct3,
      tempProduct3 */
      /* tempProduct4
      tempProduct4:
        order.productsDelivered && order.productsDelivered.tempProduct4
          ? order.productsDelivered.tempProduct4 + tempProduct4
          : tempProduct4,
      tempProduct4 */
    };

    await tier2Fulfill(productsDelivered);
  };

  useEffect(() => {
    if(order && order.statusHistory && order.statusHistory.accepted)
    console.log(order.statusHistory.accepted)
  }, [order]);

  return (
    <>
      {checkAccess(['sop', 'distributor']) && (
        <AcceptOrder
          open={showAccept}
          onAccept={handleAccept}
          setOpen={(open) => setShowAccept(open)}
        />
      )}
      {checkAccess(['sop', 'distributor']) && (
        <RejectOrder
          open={showReject}
          onReject={handleReject}
          setOpen={(open) => setShowReject(open)}
        />
      )}
      {checkAccess(['sop', 'distributor', 'fsa']) && (
        <CancelOrder
          open={showCancel}
          onCancel={handleCancel}
          setOpen={(open) => setShowCancel(open)}
        />
      )}
      {checkAccess(['fsa', 'sop', 'distributor']) && (
        <FulfillOrder
          open={showFulfill}
          order={order}
          onFulfill={handleFulfill}
          setOpen={(open) => setShowFulfill(open)}
          confirmText="Are you sure you want to fulfill the order?"
          buttonText="Fulfill"
        />
      )}
      {checkAccess(['fsa', 'sop', 'distributor']) && (
        <FulfillOrder
          open={showFulfillPartially}
          order={order}
          onFulfill={handleFulfillPartially}
          setOpen={(open) => setShowFulfillPartially(open)}
          confirmText="Are you sure you want to partially fulfill the order?"
          buttonText="Partially fulfill"
        />
      )}
      {checkAccess(['fsa', 'sop', 'distributor']) && (
        <FulfillOrder
          open={showFulfillTier2}
          order={order}
          onFulfillTier2={handleFulfillPartiallyTier2}
          tier2={true}
          setOpen={(open) => setShowFulfillTier2(open)}
          confirmText="Are you sure you want to fulfill this tier 2 order?"
          buttonText="Fulfil to tier 2 outlet"
        />
      )}
      <Loader show={loading} />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <Content>
        {loading || order ? (
          <Card className="grid grid-cols-10">
            <Back to="/orders" className="col-span-3" />
            <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {order && (
                <FontAwesomeIcon
                  icon={
                    order.createdOn === 'chatbot' ? faComment : faUserCircle
                  }
                  className="mr-2 text-lh-head-black"
                />
              )}
              {order && order.id}
            </div>
            {displayEditButton && !checkAccess(['fsm']) && (
              <Edit className="col-span-3 flex-row-reverse" />
            )}
            {order?.fulfillmentStatus === 'failed' && (
              <div className="col-span-full mx-2 my-6 px-6 py-3 rounded-3xl border-2 border-red-600 text-red-600 text-center">
                The order fulfillment of {order.outlet.name} has failed due to
                low stock!
              </div>
            )}
            <h2 className="col-span-8 font-bold">
              {order && order.outlet.name}
              {order && order.abnormalOrder && (
                <p className="col-span-full font-normal text-lh-text-black">
                  <FontAwesomeIcon icon={faWarning} /> Abnormal Order
                </p>
              )}
              <p className="col-span-full font-normal text-lh-text-black">
                <FontAwesomeIcon icon={faCirclePlus} className="mr-2 text-lh-head-black"/>
                {`${order && dayjs(order.createdAt).format('DD.MM.YYYY HH:mm')} (${(order && order.createdOn === 'app') ? order?.createdBy?.name : 'Chatbot'})`}
              </p>
              {order && order.statusHistory && order.statusHistory.accepted && (
                <p className="col-span-full font-normal text-lh-text-black">
                  <FontAwesomeIcon icon={faCircleCheck} className="mr-2 text-lh-head-black"/>
                  {`${order && dayjs(order.statusHistory.accepted.seconds * 1000).format('DD.MM.YYYY HH:mm')} (${order && order?.acceptedBy?.name})`}
                </p>
              )}
              {order && order.statusHistory.rejected && (
                <p className="col-span-full font-normal text-lh-text-black">
                  <FontAwesomeIcon icon={faCircleXmark} className="mr-2 text-lh-head-black"/>
                  {`${order && dayjs(order.statusHistory.rejected.seconds * 1000).format('DD.MM.YYYY HH:mm')} (${order && order?.rejectedBy?.name})`}
                </p>
              )}
              {order && order.statusHistory.canceled && (
                <p className="col-span-full font-normal text-lh-text-black">
                  <FontAwesomeIcon icon={faCircleXmark} className="mr-2 text-lh-head-black"/>
                  {`${order && dayjs(order.statusHistory.canceled.seconds * 1000).format('DD.MM.YYYY HH:mm')} (${order && order?.cancelledBy?.name})`}
                </p>
              )}
              {order && order.statusHistory.fulfilled && (
                <p className="col-span-full font-normal text-lh-text-black">
                  <FontAwesomeIcon icon={faTruck} className="mr-2 text-lh-head-black"/>
                  {`${order && dayjs(order.statusHistory.fulfilled.seconds * 1000).format('DD.MM.YYYY HH:mm')} (${order && order?.fulfilledBy?.name})`}
                </p>
              )}
            </h2>
            <div className="col-span-2 flex flex-col items-center font-bold text-lg text-lh-head-black">
              <p>Status</p>
              {order &&
                order.status &&
                (order.status === 'pending' ? (
                  <FontAwesomeIcon icon={faClock} className="text-2xl mt-0" />
                ) : order.status === 'accepted' ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-2xl mt-0"
                  />
                ) : order.status === 'rejected' ? (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-2xl mt-0"
                  />
                ) : order.status === 'fulfilled' ? (
                  <FontAwesomeIcon icon={faTruck} className="text-2xl mt-0" />
                ) : order.status === 'canceled' ? (
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-2xl mt-0"
                  />
                ) : (
                  ''
                ))}
            </div>
            <hr className="col-span-full mt-2 mb-2" />
            <h2 className="col-span-full font-bold text-lh-head-black">FSA</h2>
            <p className="col-span-full text-lh-text-black">
              {order && order.fsa.name}
            </p>
            <h2 className="col-span-full font-bold text-lh-head-black">
              Distributor
            </h2>
            <p className="col-span-full text-lh-text-black">
              {order && order.distributor.name}
            </p>
            <h2 className="col-span-full font-bold mt-4 mb-2 text-lh-head-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? 'Products fulfilled / ordered'
                  : 'Products ordered')}
            </h2>
            <h3 className="col-span-2 py-1 text-lh-text-black">Classic</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? (order.productsDelivered
                      ? order.productsDelivered.classic
                      : 0) +
                    '/' +
                    order.products.classic
                  : order.products.classic)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">Supaset</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? (order.productsDelivered
                      ? order.productsDelivered.supaset
                      : 0) +
                    '/' +
                    order.products.supaset
                  : order.products.supaset)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">SupafixC0</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? (order.productsDelivered
                      ? order.productsDelivered.supafixc0
                      : 0) +
                    '/' +
                    order.products.supafixc0
                  : order.products.supafixc0)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">SupafixC1</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? (order.productsDelivered
                      ? order.productsDelivered.supafixc1
                      : 0) +
                    '/' +
                    order.products.supafixc1
                  : order.products.supafixc1)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">Watershield</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? (order.productsDelivered
                      ? 'watershield' in order.productsDelivered
                        ? order.productsDelivered.watershield
                        : 0
                      : 0) +
                    '/' +
                    ('watershield' in order.products
                      ? order.products.watershield
                      : 0)
                  : 'watershield' in order.products
                  ? order.products.watershield
                  : 0)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>

            <h3 className="col-span-2 py-1 text-lh-text-black">POP</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? (order.productsDelivered
                      ? 'pop' in order.productsDelivered
                        ? order.productsDelivered.pop
                        : 0
                      : 0) +
                    '/' +
                    ('pop' in order.products
                      ? order.products.pop
                      : 0)
                  : 'pop' in order.products
                  ? order.products.pop
                  : 0)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>  
            {/* tempProduct2
            <h3 className="col-span-2 py-1 text-lh-text-black">TempProduct2</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? (order.productsDelivered
                      ? order.productsDelivered.tempProduct2
                      : 0) +
                    '/' +
                    order.products.tempProduct2
                  : order.products.tempProduct2)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            tempProduct2 */}
            {/* tempProduct3
            <h3 className="col-span-2 py-1 text-lh-text-black">TempProduct3</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? (order.productsDelivered
                      ? order.productsDelivered.tempProduct3
                      : 0) +
                    '/' +
                    order.products.tempProduct3
                  : order.products.tempProduct3)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            tempProduct3 */}
            {/* tempProduct4
            <h3 className="col-span-2 py-1 text-lh-text-black">TempProduct4</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {order &&
                (['accepted', 'fulfilled'].includes(order.status)
                  ? (order.productsDelivered
                      ? order.productsDelivered.tempProduct4
                      : 0) +
                    '/' +
                    order.products.tempProduct4
                  : order.products.tempProduct4)}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            tempProduct4 */}
            <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
              Delivery Address
            </h2>
            <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
              {order && order.address}
            </p>
            {displayComment && (
              <>
                <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
                  Comment
                </h2>
                <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
                  {order && order.comment}
                </p>
              </>
            )}

            {checkAccess(['sop', 'distributor']) && displayAcceptButton && (
              <Button
                onClick={() => setShowAccept(true)}
                color="green"
                type="button"
                className="col-span-full mt-2 py-2"
              >
                Accept
              </Button>
            )}
            {checkAccess(['sop', 'distributor']) &&
              displayRejectButton &&
              !displayCancelButton && (
                <Button
                  onClick={() => setShowReject(true)}
                  color="red"
                  type="button"
                  className="col-span-full mt-2 py-2"
                >
                  Reject
                </Button>
              )}
            {checkAccess(['sop', 'fsa', 'distributor']) &&
              displayCancelButton && (
                <Button
                  onClick={() => setShowCancel(true)}
                  color="red"
                  type="button"
                  className="col-span-full mt-2 py-2"
                >
                  Cancel
                </Button>
              )}
            {checkAccess(['fsa', 'sop', 'distributor']) &&
              displayFulfillButton && (
                <Button
                  onClick={() => setShowFulfill(true)}
                  color="blue"
                  type="button"
                  className="col-span-full mt-2 py-2"
                >
                  Fulfil
                </Button>
              )}
            {checkAccess(['fsa', 'sop', 'distributor']) &&
              displayFulfillButton && (
                <Button
                  onClick={() => setShowFulfillPartially(true)}
                  color="blue"
                  type="button"
                  className="col-span-5 mt-2 py-2"
                >
                  Partially fulfil
                </Button>
              )}
            {checkAccess(['fsa', 'sop', 'distributor']) &&
              displayFulfillButton && (
                <Button
                  onClick={() => setShowFulfillTier2(true)}
                  color="blue"
                  type="button"
                  className="col-span-5 mt-2 py-2"
                >
                  Fulfil to tier 2 outlet
                </Button>
              )}
          </Card>
        ) : (
          <Card>Order not found!</Card>
        )}
      </Content>
    </>
  );
};

export default Order;

import clsx from 'clsx';
import { ReactNode } from 'react';

type ButtonProps = {
  children?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  color?: 'green' | 'red' | 'blue' | 'gray' | 'secondary';
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  id?: string;
  text?: string;
  buttonDisabled?: boolean;
};

const Button = ({
  children,
  onClick,
  type,
  text,
  className,
  color = 'gray',
  id,
  buttonDisabled,
}: ButtonProps) => {
  if (children && text) {
    throw new Error('Cannot set children and text');
  }

  const style = clsx(
    className,
    'text-center border rounded-md cursor-pointer font-bold py-2 px-6',
    !buttonDisabled && {
      'bg-green-600 hover:bg-green-400 text-white': color === 'green',
      'bg-red-600 hover:bg-red-400 text-white': color === 'red',
      'bg-blue-600 hover:bg-blue-400 text-white': color === 'blue',
      'bg-gray-600 hover:bg-gray-400 text-white': color === 'gray',
      'bg-white border-blue-600 text-blue-600 hover:bg-blue-100':
        color === 'secondary',
    },
    buttonDisabled && 'bg-gray-100 text-gray-400 hover:cursor-not-allowed'
  );

  return (
    <button
      id={id}
      type={type}
      className={style}
      onClick={onClick}
      disabled={buttonDisabled}
    >
      {children}
      {text}
    </button>
  );
};

export default Button;

import { FirebaseError } from 'firebase/app';
import { Unsubscribe } from 'firebase/auth';
import {
  collection,
  doc,
  DocumentSnapshot,
  onSnapshot,
  query,
  Query,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import {
  Outlet,
  OutletChannel,
  CreateOutletData,
  OutletChanges,
  User,
} from '../../../../global';
import { useCheckAccess } from '../auth/use-checkAccess';
import {
  cancelOutletDeletion,
  confirmOutletChanges,
  confirmOutletChangesRejection,
  confirmOutletRegistration,
  confirmOutletRejection,
  createOutlet,
  registerOutletChanges,
  rejectOutletChanges,
  rejectOutletRegistration,
  requestOutletDeletion,
} from '../db/outlets';
import { useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { usePagination } from '../utils/usePagination';
import { useAuth } from './use-auth';
import { getTotalVolume } from '../utils/helpers';
import { SearchQuery, usePaginationTypesense } from '../utils/usePaginationTypesense';
import { useUser } from './use-user';

type OutletsHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  outlets: Outlet[];
};

type OutletsHookOptions = {
  category?: string;
  pageSize?: number;
  retailerId?: string;
  active?: boolean;
};

export const useOutlets = ({
  category,
  active,
  retailerId,
  pageSize = 10,
}: OutletsHookOptions): OutletsHook => {
  const { id } = useAuth();
  const [outletsQuery, setOutletsQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    outletsQuery,
    pageSize
  );

  useEffect(() => {
    let newQuery: Query;

    // Differentiate between outlets for retailer and all outlets
    if(retailerId) {
      if (id) {
        newQuery = query(
          collection(firestore, 'outlets'),
          where('userIds', 'array-contains', id),
          where('isDeactivated', '==', false)
        );
        if (active !== undefined) {
          newQuery = query(newQuery, where('active', '==', active))
        }
        if (retailerId) {
          newQuery = query(newQuery, where('retailer.id', '==', retailerId));
        }
        setOutletsQuery(newQuery);
      }
    } else {
      // List of all outlets
      if (id) {
        newQuery = query(
          collection(firestore, 'outlets'),
          where('userIds', 'array-contains', id),
          where('isDeactivated', '==', false)
        );
        if (active !== undefined) {
          newQuery = query(newQuery, where('active', '==', active))
        }
        if (category) {
          switch (category) {
            case 'actionRequired': {
              newQuery = query(newQuery, where('actionRequired', '==', true));
              break;
            }
            case 'numberWrong': {
              newQuery = query(newQuery, where('retailer.phoneCorrect', '==', false));
              break;
            }
            case 'noWhatsApp': {
              newQuery = query(newQuery, where('retailer.hasWhatsApp', '==', false));
              break;
            }
            case 'enabledWhatsApp': {
              newQuery = query(newQuery, where('retailer.hasWhatsApp', '==', true));
              break;
            }
          }
        }

        setOutletsQuery(newQuery);
      }
    }
  }, [id, retailerId, active, category]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      outlets: values
        ? values.map((v) => prepareOutlet(docToJSON(v) as Outlet))
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useOutletsTypesense = ({
  category,
  searchText,
  pageSize = 10,
}) => {
  const { id, typesenseKeyUserIds } = useAuth();
  const [outletsQuery, setOutletsQuery] = useState<SearchQuery>();
  const { error, hasMore, load, loading, reset, values } =
  usePaginationTypesense(
    outletsQuery,
    pageSize,
    'outletsCollection',
    typesenseKeyUserIds
  );

  useEffect(() => {
    let categoryFilter = 'isDeactivated:=false';
    if (category) {
      switch (category) {
        case 'actionRequired': {
          categoryFilter += '&&actionRequired:=true';
          break;
        }
        case 'numberWrong': {
          categoryFilter += '&&retailer.phoneCorrect:=false';
          break;
        }
        case 'noWhatsApp': {
          categoryFilter += '&&retailer.hasWhatsApp:=false';
          break;
        }
        case 'enabledWhatsApp': {
          categoryFilter += '&&retailer.hasWhatsApp:=true';
          break;
        }
      }
    }

    if (typesenseKeyUserIds && id && searchText) {
      let search = {
        q: searchText,
        query_by: 'name',
        filter_by: `${categoryFilter}`,
      };

      setOutletsQuery(search);
    } else if (outletsQuery) {
      setOutletsQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, category, pageSize, searchText, typesenseKeyUserIds]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      outlets: values
        ? values.map((v) => {
            return { ...v.document, id: v.document.id } as Outlet;
          })
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};


type OutletHook = {
  error: FirebaseError;
  loading: boolean;
  reset: () => void;
  update: (changes: OutletChanges) => Promise<void>;
  create: (data: CreateOutletData, user: User) => Promise<void>;
  confirmRegistration: (confirmed: boolean, comment?: string) => Promise<void>;
  confirmChanges: (
    confirmed: boolean,
    changes: OutletChanges,
    comment?: string
  ) => Promise<void>;
  confirmRejection: () => Promise<void>;
  confirmChangesRejection: () => Promise<void>;
  requestDeletion: () => Promise<void>;
  cancelDeletion: () => Promise<void>;
  outlet: Outlet | null;
};

export const useOutlet = (id?: string): OutletHook => {
  const checkAccess = useCheckAccess();
  const { id: userId } = useAuth();
  const { error, loading, reset, setError, setValue, value, setLoading } =
    useLoadingValue<DocumentSnapshot, FirebaseError>();
  const { user } = useUser(); 

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      if (!loading) setLoading(true);
      const ref = doc(firestore, 'outlets', id);
      unsubscribe = onSnapshot(ref, setValue, setError);
    } else {
      setLoading(false);
    }
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const create = async (data: CreateOutletData, user: User) => {
    try {
      setLoading(true);
      await createOutlet(data, user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const update = async (changes: OutletChanges) => {
    if (!id) return;
    try {
      setLoading(true);
      await registerOutletChanges(id, checkAccess(['sop']), changes, user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmRegistration = async (confirmed: boolean, comment?: string) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmOutletRegistration(id, user)
        : await rejectOutletRegistration(id, comment, user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChanges = async (
    confirmed: boolean,
    changes: OutletChanges,
    comment?: string
  ) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmOutletChanges(id, changes, user)
        : await rejectOutletChanges(id, changes, comment, user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmRejection = async () => {
    if (!id || !checkAccess(['fsa'])) return;
    try {
      setLoading(true);
      confirmOutletRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChangesRejection = async () => {
    if (!id || !checkAccess(['fsa'])) return;
    try {
      setLoading(true);
      confirmOutletChangesRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const requestDeletion = async () => {
    if (!id || !checkAccess(['fsa', 'sop', 'fsm'])) return;
    try {
      setLoading(true);
      requestOutletDeletion(id, {
        id: userId,
        name: user.name,
        phone: user.phone,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const cancelDeletion = async () => {
    if (!id || !checkAccess(['fsa', 'sop', 'fsm'])) return;
    try {
      setLoading(true);
      cancelOutletDeletion(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      loading,
      reset,
      update,
      create,
      confirmRegistration,
      confirmChanges,
      confirmRejection,
      confirmChangesRejection,
      requestDeletion,
      cancelDeletion,
      outlet: value ? prepareOutlet(docToJSON(value) as Outlet) : null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, value]
  );
};


const prepareOutlet = (outlet: Outlet): Outlet => {
  if (!outlet) return null;
  let pendingVolume =
    getTotalVolume(outlet.ordersVolumeAppPending) +
    getTotalVolume(outlet.ordersVolumeChatbotPending);
  let acceptedVolume =
    getTotalVolume(outlet.ordersVolumeChatbotAccepted) +
    getTotalVolume(outlet.ordersVolumeAppAccepted);
  let fulfilledVolume =
    getTotalVolume(outlet.ordersVolumeChatbotFulfilled) +
    getTotalVolume(outlet.ordersVolumeAppFulfilled);
  let fulfilledVolumeChatbot = getTotalVolume(
    outlet.ordersVolumeChatbotFulfilled
  );
  let fulfilledVolumeApp = getTotalVolume(outlet.ordersVolumeAppFulfilled);
  return {
    ...outlet,
    pendingVolume,
    acceptedVolume,
    fulfilledVolume,
    fulfilledVolumeChatbot,
    fulfilledVolumeApp,
    channelName: getChannelName(outlet.channel),
    channelNameChanges: getChannelName(outlet?.changes?.channel),
  };
};

const getChannelName = (channel: OutletChannel): string => {
  if (!channel) return '';
  const channels = {
    blockmaker: 'Blockmaker',
    blockmaker_retailer: 'Blockmaker/Retailer',
    container: 'Container',
    neighborhood_shop: 'Neighborhood Shop',
    palleter: 'Palleter',
    tile_seller: 'Tile Seller',
  };
  return channels[channel];
};

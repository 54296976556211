import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../components/ui/Card';
import Back from '../../../components/ui/Back';
import Content from '../../../components/layout/Content';
import { useQuestion } from '../../../lib/hooks/use-questions';

type QuestionDetailsParams = 'id';

const FAQ = () => {
  const { id } = useParams<QuestionDetailsParams>();
  const {
    loading,
    question,
  } = useQuestion(id);

  useEffect(() => {
    console.log(question);
  }, [question]);

  return (
    <>
      <Content>
        {loading || question ? (
          <Card className="grid grid-cols-10">
            <Back to="/orders" className="col-span-3" />
            <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {question && question.question}
            </div>
            <div className="col-span-12 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {question && question.answer}
            </div>
          </Card>
        ) : (
          <Card>Question not found!</Card>
        )}
      </Content>
    </>
  );
};

export default FAQ;

import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  Query,
  query,
  Unsubscribe,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import {
  ProductDetails,
} from '../../../../global';
import { firestore, docToJSON } from '../utils/firebase';
import { unflatten } from '../utils/helpers';
import { usePagination } from '../utils/usePagination';
import {
  SearchQuery,
  usePaginationTypesense,
} from '../utils/usePaginationTypesense';
import { useAuth } from './use-auth';

type ProductsHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  products: ProductDetails[];
};

type ProductsHookOptions = {
  pageSize?: number;
};

export const useProducts = ({pageSize}: ProductsHookOptions): ProductsHook => {
  const { id } = useAuth();
  const [productsQuery, setProductsQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    productsQuery,
    pageSize
  );

  useEffect(() => {
    if (id) {
      setProductsQuery(
        query(
          collection(firestore, 'products'),
        )
      );
    } else if (productsQuery) {
      setProductsQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      products: values
        ? values.map((v) => docToJSON(v) as ProductDetails)
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useQuestionsTypesense = ({
  direction = 'asc',
  searchText,
  pageSize = 10,
}) => {
  const { id, typesenseKeyUserIds } = useAuth();
  const [questionsQuery, setQuestionsQuery] = useState<SearchQuery>();
  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense(
      questionsQuery,
      pageSize,
      'faq',
      typesenseKeyUserIds
    );

  useEffect(() => {
    if (typesenseKeyUserIds && id && searchText) {
      let search = {
        q: searchText,
        filter_by: '',
        query_by: 'question,answer,feature',
        sort_by: `sort_by:created_at${direction}`,
      };

      setQuestionsQuery(search);
    } else if (questionsQuery) {
      setQuestionsQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageSize, direction, searchText, typesenseKeyUserIds]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      questions: values
        ? values.map((v) => {
            return unflatten({
                ...v.document,
                id: v.document.id,
                createdAt: v.document.createdAt * 1000,
              }
            ) as any;
          })
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

interface UseProductHook {
  error: FirestoreError;
  loading: boolean;
  product: ProductDetails;
}

export const useProduct = (id?: string): UseProductHook => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<ProductDetails | undefined>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'products', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setProduct(docToJSON(doc) as ProductDetails);
          } else {
            setProduct(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    }
    return unsubscribe;
  }, [id]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    error,
    loading,
    product
  };
};

import {
  doc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from '../utils/firebase';
import { CreatePOSMRequestData, UpdatePOSMRequestData } from '../../../../global';

export const createPOSMRequest = async (data: CreatePOSMRequestData, id) => {
  const ref = doc(firestore, 'posmRequest', id);
  await setDoc(ref, {outletId: id, ...data});
};

export const updatePOSMRequest = async (data: UpdatePOSMRequestData, id: string) => {
  const ref = doc(firestore, 'posmRequest', id);
  await updateDoc(ref, data);
};
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../components/ui/Card';
import Back from '../../../components/ui/Back';
import Content from '../../../components/layout/Content';
import { useProduct } from '../../../lib/hooks/use-products';

type ProductParams = 'id';

const Product = () => {
  const { id } = useParams<ProductParams>();
  const {
    loading,
    product,
  } = useProduct(id);

  useEffect(() => {
    console.log(product);
  }, [product]);

  return (
    <>
      <Content>
        {loading || product ? (
          <Card className="grid grid-cols-10">
            <Back to="/products" className="col-span-3" />
            <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {product && product.defaultName}
            </div>
            <div className="col-span-12 flex justify-center items-center text-center text-xl text-lh-head-black">
              {product && product.description}
            </div>
            <br/>
            <div className="col-span-12 flex justify-center items-center text-center">
              <img src={product && product.imageURL ? product.imageURL : ''} alt={product && product.defaultName} height={250} width={250}/>
            </div>
           
          </Card>
        ) : (
          <Card>Product not found!</Card>
        )}
      </Content>
    </>
  );
};

export default Product;
